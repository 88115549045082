import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import BudgetForecastsWrapper from '../pages/budgetForecast/BudgetForecastsWrapper'
import FeedgenPage from "../modules/feedgen/components/FeedgenPage"
import LogsPage from "../modules/logs/LogsPage"
import EmailTemplateWrapper from "../pages/emailTemplates/EmailTemplateWrapper"
import EmailHistoryWrapper from "../pages/emailHistory/EmailHistoryWrapper"

export function PrivateRoutes() {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../pages/users/UsersWrapper'))
  const PlatformWrapper = lazy(() => import('../pages/platforms/PlatformsWrapper'))
  const PriceListsWrapper = lazy(() => import('../pages/priceLists/PriceListsWrapper'))
  const ProjectsWrapper = lazy(() => import('../pages/projects/ProjectsWrapper'))
  const ClientsWrapper = lazy(() => import('../pages/clients/ClientsWrapper'))
  const DiscountCodeWrapper = lazy(() => import('../pages/discountCodes/DiscountCodesWrapper'))
  const TransactionsWrapper = lazy(() => import ("../pages/payments/TransactionsWrapper"))
  const PaymentSubscriptionsWrapper = lazy(() => import ("../pages/payments/SubscriptionsWrapper"))
  const CountryIndexWrapper = lazy(() => import ("../pages/countries/CountryIndexWrapper"))
  const NegativeKeywordsWrapper = lazy(
    () => import('../pages/negativeKeywords/NegativeKeywordsWrapper')
  )

  const SettingsWrapper = lazy(() => import('../pages/settings/SettingsWrapper'))
  const AdvertisingChannelWrapper = lazy(
    () => import('../pages/advertisingChannel/AdvertisingChannelsWrapper')
  )
  const SubscriptionsWrapper = lazy(() => import('../pages/subscriptions/SubscriptionsWrapper'))
  const SubscriptionPage = lazy(() => import('../modules/subscriptions/SubscriptionPage'))

  const DocumentsWrapper = lazy(() => import('../pages/documents/DocumentsWrapper'))
  const DocumentPage = lazy(() => import('../modules/documents/DocumentPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/users' component={UsersPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/subscriptions' component={SubscriptionsWrapper} />
        <Route path='/subscription/overview' component={SubscriptionPage} />
        <Route path='/subscription/invoices' component={SubscriptionPage} />
        <Route path='/subscription/user-projects' component={SubscriptionPage} />
        <Route path='/subscription/campaigns' component={SubscriptionPage} />
        <Route path='/subscription/user-payments' component={SubscriptionPage} />
        <Route path='/documents' component={DocumentsWrapper} />
        <Route path='/document/proformas' component={DocumentPage} />
        <Route path='/document/invoices' component={DocumentPage} />
        <Route path='/projects' component={ProjectsWrapper} />
        <Route path='/clients' component={ClientsWrapper} />
        <Route path='/platforms' component={PlatformWrapper} />
        <Route path='/price_lists' component={PriceListsWrapper} />
        <Route path='/settings' component={SettingsWrapper} />
        <Route path='/channels' component={AdvertisingChannelWrapper} />
        <Route path='/budgetForecasts' component={BudgetForecastsWrapper} />
        <Route path='/discountCodes' component={DiscountCodeWrapper} />
        <Route path='/negativeKeywords' component={NegativeKeywordsWrapper} />
        <Route path='/payments/transactions' component={TransactionsWrapper} />
        <Route path='/payments/subs' component={PaymentSubscriptionsWrapper} />
        <Route path='/country-languages' component={CountryIndexWrapper} />
        <Route path='/feedgen' component={FeedgenPage} />
        <Route path='/logs' component={LogsPage} />
        <Route path='/email-templates' component={EmailTemplateWrapper} />
        <Route path='/email-history' component={EmailHistoryWrapper} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
