import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {Action} from '@reduxjs/toolkit'

import * as auth from '../../app/modules/auth'
import {reducer as userReducer} from '../../app/modules/users/redux/UsersRedux'
import {saga as userSaga} from '../../app/modules/users/redux/UsersRedux'
import {reducer as profileReducer} from '../../app/modules/accounts/components/settings/redux/ProfileRedux'
import {reducer as platformReducer} from '../../app/modules/platforms/redux/PlatformsRedux'
import {reducer as priceListsReducer} from '../../app/modules/priceLists/redux/PriceListsRedux'
import {reducer as projectsReducer} from '../../app/modules/projects/redux/ProjectsRedux'
import {reducer as clientsReducer} from '../../app/modules/clients/redux/ClientsRedux'
import {reducer as subscriptionReducer} from '../../app/modules/subscriptions/redux/SubscriptionsRedux'
import {reducer as userProjectReducer} from '../../app/modules/subscriptions/redux/UserProjectsRedux'
import {reducer as userPaymentReducer} from '../../app/modules/subscriptions/redux/UserPaymentsRedux'
import {reducer as invoiceReducer} from '../../app/modules/documents/redux/InvoicesRedux'
import {reducer as campaignReducer} from '../../app/modules/subscriptions/redux/CampaignsRedux'
import {reducer as settingReducer} from '../../app/modules/settings/redux/SettingsRedux'
import {reducer as advertisingChannelReducer} from '../../app/modules/advertisingChannel/redux/AdvertisingChannelsRedux'
import {reducer as budgetForecastsReducer} from '../../app/modules/budgetForecasts/redux/BudgetForecastsRedux'
import {reducer as discountCodeReducer} from '../../app/modules/discountCodes/redux/DiscountCodesRedux'
import {reducer as negativeKeywordReducer} from '../../app/modules/negativeKeywords/redux/NegativeKeywordsRedux'
import {reducer as countryReducer} from "../../app/modules/countries/redux/CountryRedux"
import {reducer as subscriptionPaymentReducer} from '../../app/modules/payments/redux/SubscriptionRedux'
import {reducer as transactionsReducer} from '../../app/modules/payments/redux/TransactionRedux'
import {reducer as attributeReducer} from "../../app/modules/feedgen/redux/attributes/AttributeRedux"
import {reducer as googleCategoryReducer} from "../../app/modules/feedgen/redux/googleCategories/GoogleCategoryRedux"
import {reducer as promptReducer} from "../../app/modules/feedgen/redux/prompts/PromptRedux"
import {reducer as googleAdsLogsReducer} from "../../app/modules/logs/redux/googleAds/GoogleAdsRedux"
import {reducer as microsoftAdsErrorsReducer} from "../../app/modules/logs/redux/microsoftAds/ErrorRedux"
import {reducer as microsoftAdsRequestsReducer} from "../../app/modules/logs/redux/microsoftAds/RequestRedux"
import {reducer as emailTemplateRedux} from "../../app/modules/emailTemplates/redux/EmailTemplateRedux"
import {reducer as emailHistoryRedux} from "../../app/modules/emailHistory/redux/EmailHistoryRedux"
import {reducer as summaryReducer} from "../../app/modules/subscriptions/redux/SummaryRedux"
import {reducer as woocommerceReducer} from "../../app/modules/logs/redux/WooCommerce/WooCommerceRedux"

import {saga as profileSaga} from '../../app/modules/accounts/components/settings/redux/ProfileRedux'
import {saga as platformSaga} from '../../app/modules/platforms/redux/PlatformsRedux'
import {saga as priceListsSaga} from '../../app/modules/priceLists/redux/PriceListsRedux'
import {saga as projectsSaga} from '../../app/modules/projects/redux/ProjectsRedux'
import {saga as clientsSaga} from '../../app/modules/clients/redux/ClientsRedux'
import {saga as subscriptionSaga} from '../../app/modules/subscriptions/redux/SubscriptionsRedux'
import {saga as userProjectsSaga} from '../../app/modules/subscriptions/redux/UserProjectsRedux'
import {saga as userPaymentsSaga} from '../../app/modules/subscriptions/redux/UserPaymentsRedux'
import {saga as invoiceSaga} from '../../app/modules/documents/redux/InvoicesRedux'
import {saga as campaignSaga} from '../../app/modules/subscriptions/redux/CampaignsRedux'
import {saga as settingSaga} from '../../app/modules/settings/redux/SettingsRedux'
import {saga as advertisingChannelSaga} from '../../app/modules/advertisingChannel/redux/AdvertisingChannelsRedux'
import {saga as budgetForecastSaga} from '../../app/modules/budgetForecasts/redux/BudgetForecastsRedux'
import {saga as discountCodeSaga} from '../../app/modules/discountCodes/redux/DiscountCodesRedux'
import {saga as negativeKeywordSaga} from '../../app/modules/negativeKeywords/redux/NegativeKeywordsRedux'
import {saga as transactionsSaga} from '../../app/modules/payments/redux/TransactionRedux'
import {saga as subscriptionPaymentSaga} from "../../app/modules/payments/redux/SubscriptionRedux"
import {saga as countrySaga} from "../../app/modules/countries/redux/CountryRedux"
import {saga as attributeSaga} from "../../app/modules/feedgen/redux/attributes/AttributeRedux"
import {saga as googleCategorySaga} from "../../app/modules/feedgen/redux/googleCategories/GoogleCategoryRedux"
import {saga as promptSaga} from "../../app/modules/feedgen/redux/prompts/PromptRedux"
import {saga as googleAdsLogsSaga} from "../../app/modules/logs/redux/googleAds/GoogleAdsRedux"
import {saga as microsoftAdsRequestSaga} from "../../app/modules/logs/redux/microsoftAds/RequestRedux"
import {saga as microsoftAdsErrorSaga} from "../../app/modules/logs/redux/microsoftAds/ErrorRedux"
import {saga as emailTemplateSaga} from "../../app/modules/emailTemplates/redux/EmailTemplateRedux"
import {saga as emailHistorySaga} from "../../app/modules/emailHistory/redux/EmailHistoryRedux"
import {saga as summarySaga} from "../../app/modules/subscriptions/redux/SummaryRedux"
import {saga as woocommerceSaga} from "../../app/modules/logs/redux/WooCommerce/WooCommerceRedux"

export const rootReducer = combineReducers({
    auth: auth.reducer,
    users: userReducer,
    profile: profileReducer,
    platforms: platformReducer,
    priceLists: priceListsReducer,
    projects: projectsReducer,
    clients: clientsReducer,
    subscriptions: subscriptionReducer,
    invoices: invoiceReducer,
    campaigns: campaignReducer,
    userProjects: userProjectReducer,
    userPayments: userPaymentReducer,
    settings: settingReducer,
    advertisingChannel: advertisingChannelReducer,
    budgetForecast: budgetForecastsReducer,
    discountCode: discountCodeReducer,
    negativeKeyword: negativeKeywordReducer,
    transactions: transactionsReducer,
    paymentSubscription: subscriptionPaymentReducer,
    countries: countryReducer,
    attributes: attributeReducer,
    googleCategories: googleCategoryReducer,
    prompts: promptReducer,
    googleAdsLogs: googleAdsLogsReducer,
    microsoftAdsErrors: microsoftAdsErrorsReducer,
    microsoftAdsRequests: microsoftAdsRequestsReducer,
    emailTemplates: emailTemplateRedux,
    summary: summaryReducer,
    woocommerce: woocommerceReducer,
    emailHistory: emailHistoryRedux

})

export type RootState = ReturnType<typeof rootReducer>

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export interface ActionWithDefinePayload<T> extends Action {
    payload: T
}

export interface IErrorResponse {
    [key: string]: string[]
}

export function* rootSaga() {
    yield all([
        auth.saga(),
        userSaga(),
        profileSaga(),
        platformSaga(),
        priceListsSaga(),
        projectsSaga(),
        clientsSaga(),
        subscriptionSaga(),
        userProjectsSaga(),
        userPaymentsSaga(),
        invoiceSaga(),
        campaignSaga(),
        settingSaga(),
        advertisingChannelSaga(),
        budgetForecastSaga(),
        discountCodeSaga(),
        negativeKeywordSaga(),
        transactionsSaga(),
        subscriptionPaymentSaga(),
        countrySaga(),
        attributeSaga(),
        googleCategorySaga(),
        promptSaga(),
        googleAdsLogsSaga(),
        microsoftAdsRequestSaga(),
        microsoftAdsErrorSaga(),
        emailTemplateSaga(),
        summarySaga(),
        woocommerceSaga(),
        emailHistorySaga()
    ])
}
