import axios from 'axios'
import {ISubscription} from '../models/Subscription'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const SUBSCRIPTION_API = `${API_URL}/subscriptions`

export async function getSubscriptionsList(
    page: number,
    perPage: number,
    filter: string,
    search?: string
) {
  const params: Record<string, string | number> = {
    page: encodeURIComponent(page.toString()),
    'per-page': encodeURIComponent(perPage.toString()),
    sort: encodeURIComponent(filter),
  };

  if (search) {
    params.search = encodeURIComponent(search.trim());
  }

  return await axios.get(SUBSCRIPTION_API, { params });
}

export async function getSubscriptionById(subcriptionId: string) {
  if(subcriptionId) {
    return await axios.get(`${SUBSCRIPTION_API}/${subcriptionId}`)
  }
}

export async function deleteSubscription(subcriptionId: string) {
  return await axios.delete(`${SUBSCRIPTION_API}/${subcriptionId}`)
}

export async function updateSubscription(updateSubscription: ISubscription) {
  return await axios
    .put(`${SUBSCRIPTION_API}/${updateSubscription.id}`, updateSubscription)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
