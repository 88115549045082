export interface IEmailHistory {
    id: number
    service: string
    action: string
    subject: string
    content: string
    from: string
    to: string
    sent: number
    error_code: string | null
    error_content: string | null
    created_at: string
    attachments: string | null
}

export const LIST_FILTERS = [
    { value: 'sort=-created_at', label: 'CREATED_AT.SORT.DOWN' },
    { value: 'sort=created_at', label: 'CREATED_AT.SORT.UP' },
    { value: 'sort=-to', label: 'RECEIVER.SORT.DOWN' },
    { value: 'sort=to', label: 'RECEIVER.SORT.UP' },
]