import React from 'react'
import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from 'react-intl'
import EmailTemplates from "../../modules/emailTemplates/components/EmailTemplates";
import EmailHistory from "../../modules/emailHistory/components/EmailHistory";

const EmailHistoryWrapper: React.FC = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'EMAIL_HISTORY'})}</PageTitle>
            <EmailHistory/>
        </>
    )
}

export default EmailHistoryWrapper