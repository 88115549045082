export interface IWooCommerce {
    id?: number;
    date: string;
    domain: string;
    channel: string;
    tags: string;
}

export const SORT_OPTIONS = [
    { value: 'sort=-date', label: 'CLIENTS.LIST.SORT.CREATE_DOWN' },
    { value: 'sort=date', label: 'CLIENTS.LIST.SORT.CREATE_UP' },
]

export const CHANNELS = [
    { value: '', label: 'ALL' },
    { value: 'channel=bing', label: 'MICROSOFT_ADS' },
    { value: 'channel=google', label: 'GOOGLE' },
]
