import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {actions} from '../redux/WooCommerce/WooCommerceRedux'
import {useIntl} from 'react-intl'
import {Table} from 'react-bootstrap'
import PageItemsSelect from "../../common/PageItemsSelect/PageItemsSelect"
import Pagination from "../../common/Pagination/Pagination"
import {IWooCommerce, SORT_OPTIONS, CHANNELS } from "../models/WooCommerce";

const WooCommerce: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const logs = useSelector((state: RootState) => state.woocommerce.logs || [])
    const loading = useSelector((state: RootState) => state.woocommerce.loading)

    const [sortOrder, setSortOrder] = useState<string>(SORT_OPTIONS[0].value)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const itemsPerPage = 10

    const totalPages = Math.ceil(logs.length / itemsPerPage)
    const pagedLogs = logs.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    )

    useEffect(() => {
        console.log(CHANNELS)
        dispatch(actions.requestLogs(sortOrder))
        console.log(logs)
    }, [dispatch, sortOrder])

    const handleSortChange = useCallback((event) => {
        setSortOrder(event.target.value)
        dispatch(actions.requestLogs(event.target.value))
    }, [dispatch])

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({id: 'WOOCOMMERCE_LOGS'})}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="col-9">
                            <PageItemsSelect
                                defaultValue={sortOrder}
                                handle={handleSortChange}
                                options={SORT_OPTIONS}
                            />
                        </div>
                        <div className="col-4">
                            <PageItemsSelect
                                defaultValue={sortOrder}
                                handle={handleSortChange}
                                options={CHANNELS}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {loading ? (
                <p>{intl.formatMessage({id: 'LOADING'})}...</p>
            ) : pagedLogs.length > 0 ? (
                <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-4">
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th>ID</th>
                        <th>{intl.formatMessage({id: 'DATE'})}</th>
                        <th>{intl.formatMessage({id: 'DOMAIN'})}</th>
                        <th>{intl.formatMessage({id: 'CHANNEL'})}</th>
                        <th>{intl.formatMessage({id: 'TAGS'})}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pagedLogs.map((log: IWooCommerce) => (
                        <tr key={log.id}>
                            <td>{log.id}</td>
                            <td style={{ minWidth: '150px' }}>{log.date}</td>
                            <td>{log.domain}</td>
                            <td className="text-capitalize">{log.channel}</td>
                            <td>
                                {log.tags ? (
                                    <pre className="text-wrap text-break">
                                        {JSON.stringify(log.tags, null, 2)}
                                    </pre>
                                ) : '-'}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <p>{intl.formatMessage({id: 'NO_LOGS_FOUND'})}</p>
            )}

            <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    )
}

export default WooCommerce
