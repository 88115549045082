import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const EMAIL_HISTORY_API = `${API_URL}/emails`

export async function fetchEmailHistory(page: number, limitPerPage: number, filter: string = '', searchQuery: string = '') {
    try {
        const res = await axios.get(`${EMAIL_HISTORY_API}?page=${page}&per-page=${limitPerPage}&${filter}&search=${searchQuery}`)
        return res.data
    } catch (err) {
        throw new Error(`Error fetching email history: ${err}`)
    }
}