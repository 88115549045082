import {
  ActionWithDefinePayload,
  ActionWithPayload,
  RootState,
} from '../../../../setup/redux/RootReducer'
import {ISummary} from "../models/Summary"
import {getSummary} from "./SummaryCRUD";
import {put, takeLatest} from "redux-saga/effects";

export const SUMMARY_REQUEST = 'SUMMARY_REQUEST'
export const SUMMARY_REQUEST_SUCCESS = 'SUMMARY_REQUEST_SUCCESS'
export const SUMMARY_REQUEST_FAIL = 'SUMMARY_REQUEST_FAIL'

export interface ISummaryState {
  summary?: ISummary
  loading: boolean
  error: boolean
}

const initialSummaryState: ISummaryState = {
  summary: undefined,
  loading: false,
  error: false,
}

export const reducer = (
    state: ISummaryState = initialSummaryState,
    action: ActionWithPayload<ISummaryState>
): ISummaryState => {
  switch (action.type) {
    case SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case SUMMARY_REQUEST_SUCCESS:
      return {
        ...state,
        summary: action.payload?.summary,
        loading: false,
        error: false,
      }
    case SUMMARY_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export const actions = {
  requestSummary: (clientId: string) => ({
    type: SUMMARY_REQUEST,
    payload: {clientId},
  }),
  requestSummarySuccess: (summary: ISummary) => ({
    type: SUMMARY_REQUEST_SUCCESS,
    payload: {summary},
  }),
  requestSummaryFail: () => ({
    type: SUMMARY_REQUEST_FAIL,
  }),
}

export const selectors = {
  getSummary: (state: RootState) => state.summary.summary,
  isLoading: (state: RootState) => state.summary.loading,
  hasError: (state: RootState) => state.summary.error,
}

function* listUpdate(clientId: string) {
  const {data} = yield getSummary(clientId)
  return {data}
}

export function* saga() {
  yield takeLatest(
      SUMMARY_REQUEST,
      function* fetchSummarySaga(action: ActionWithDefinePayload<{clientId: string}>) {
        try {
          const {data} = yield getSummary(action.payload.clientId)
          yield put(actions.requestSummarySuccess(data))
        } catch (error) {
          yield put(actions.requestSummaryFail())
        }
      }
  )
}