import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'
import { actions, selectors } from '../redux/EmailHistoryRedux'
import { RootState } from '../../../../setup'
import PageItemsSelect from "../../common/PageItemsSelect/PageItemsSelect"
import { LIST_FILTERS } from '../models/EmailHistory'
import Pagination from '../../common/Pagination/Pagination'
import { ITEM_PER_PAGE } from "../../common/PageItemsSelect/models/PerPage"
import { debounce } from 'lodash'

const EmailHistory: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const emailHistory = useSelector((state: RootState) => selectors.getEmailHistory(state) || [])
    const isLoading = useSelector((state: RootState) => selectors.isLoading(state))
    const error = useSelector((state: RootState) => selectors.hasError(state))
    const totalPages = useSelector((state: RootState) => state.emailHistory.totalPages)
    const [listFilter, setListFilter] = useState<string>(LIST_FILTERS[0].value)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [limitPerPage, setLimitPerPage] = useState<number>(10)

    const handleFilterChange = useCallback(
        (event: React.ChangeEvent<Element>) => {
            const target = event.target as HTMLSelectElement
            const value = target.value
            setListFilter(value)
            setCurrentPage(1)
        },
        []
    )

    const debouncedSearch = useCallback(
        debounce((query: string) => {
            dispatch(actions.requestEmailHistory(currentPage, listFilter, limitPerPage, query))
        }, 1000),
        [currentPage, listFilter, limitPerPage]
    )

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value
        setSearchQuery(query)
        debouncedSearch(query)
    }

    const onSelectHandle = useCallback((event: React.ChangeEvent<Element>) => {
        const target = event.target as HTMLSelectElement
        setLimitPerPage(Number(target.value))
        setCurrentPage(1)
    }, [])

    useEffect(() => {
        dispatch(actions.requestEmailHistory(currentPage, listFilter, limitPerPage, searchQuery))
    }, [dispatch, listFilter, currentPage, limitPerPage])

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error fetching email history</div>
    }

    const renderAttachments = (attachments: string | string[] | null) => {
        if (!attachments) {
            return <span>-</span>
        }

        try {
            const parsedAttachments = typeof attachments === 'string' ? JSON.parse(attachments) : attachments

            if (Array.isArray(parsedAttachments) && parsedAttachments.length > 0) {
                return (
                    <ul style={{ padding: '.7rem', margin: 0 }}>
                        {parsedAttachments.map((attachment, index) => (
                            <li key={index}>{attachment.name || attachment}</li>
                        ))}
                    </ul>
                )
            }
        } catch (e) {
            console.error('Error parsing attachments:', e)
        }

        return <span>{intl.formatMessage({ id: 'NO_ATTACHMENTS' })}</span>
    }

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-body mt-5'>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-between align-items-center'>
                            <h3 className='mb-0'>
                                {intl.formatMessage({ id: 'EMAIL_HISTORY_LIST' })}
                            </h3>
                            <div className='d-flex gap-2 align-items-center'>
                                <PageItemsSelect
                                    defaultValue={listFilter}
                                    handle={handleFilterChange}
                                    options={LIST_FILTERS}
                                />
                                <input
                                    type='text'
                                    placeholder={intl.formatMessage({ id: 'SEARCH' })}
                                    className='form-control form-control-sm w-auto'
                                    style={{ minWidth: '150px', minHeight: '40px' }}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='py-3'>
                        <Table bordered className='table table-responsive table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                            <tr>
                                <th>{intl.formatMessage({ id: 'SERVICE' })}</th>
                                <th style={{ minWidth: '200px' }}>{intl.formatMessage({ id: 'SUBJECT' })}</th>
                                <th>{intl.formatMessage({ id: 'CONTENT' })}</th>
                                <th>{intl.formatMessage({ id: 'RECEIVER' })}</th>
                                <th>{intl.formatMessage({ id: 'ATTACHMENTS' })}</th>
                                <th style={{ minWidth: '150px' }}>{intl.formatMessage({ id: 'SEND_DATE' })}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {emailHistory.length > 0 ? (
                                emailHistory.map((email) => (
                                    <tr key={email.id}>
                                        <td>{email.service}</td>
                                        <td>{email.subject}</td>
                                        <td dangerouslySetInnerHTML={{ __html: email.content }} />
                                        <td>{email.to}</td>
                                        <td>{renderAttachments(email.attachments)}</td>
                                        <td>{new Date(email.created_at).toLocaleString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6} className='text-center'>
                                        {intl.formatMessage({ id: 'NO_DATA' })}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                    <div className='d-flex flex-stack flex-wrap pt-10'>
                        <div className='fs-6'>
                            <PageItemsSelect
                                defaultValue={limitPerPage}
                                handle={onSelectHandle}
                                options={ITEM_PER_PAGE}
                            />
                        </div>
                        <Pagination
                            pages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailHistory