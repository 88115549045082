import { put, takeLatest, call } from 'redux-saga/effects'
import { RootState } from '../../../../setup'
import {fetchEmailHistory} from "./EmailHistoryCRUD"
import {IEmailHistory} from "../models/EmailHistory"
export const EMAIL_HISTORY_REQUEST = 'EMAIL_HISTORY_REQUEST'
export const EMAIL_HISTORY_REQUEST_SUCCESS = 'EMAIL_HISTORY_REQUEST_SUCCESS'
export const EMAIL_HISTORY_REQUEST_FAIL = 'EMAIL_HISTORY_REQUEST_FAIL'
export const CHANGE_EMAIL_HISTORY_FILTER = 'CHANGE_EMAIL_HISTORY_FILTER'

export const actions = {
    requestEmailHistory: (page: number, filter: string, limitPerPage: number, searchQuery: string) => ({
        type: EMAIL_HISTORY_REQUEST,
        payload: { page, filter, limitPerPage, searchQuery },
    }),
    requestEmailHistorySuccess: (history: IEmailHistory[], totalPages: number) => ({
        type: EMAIL_HISTORY_REQUEST_SUCCESS,
        payload: { history, totalPages },
    }),
    requestEmailHistoryFail: () => ({ type: EMAIL_HISTORY_REQUEST_FAIL }),
}

interface IEmailHistoryState {
    history: IEmailHistory[]
    loading: boolean
    error: boolean
    filter: string
    totalPages: number
}

const initialState: IEmailHistoryState = {
    history: [],
    loading: false,
    error: false,
    filter: '',
    totalPages: 1,
}

export const reducer = (
    state: IEmailHistoryState = initialState,
    action: any
): IEmailHistoryState => {
    switch (action.type) {
        case EMAIL_HISTORY_REQUEST:
            return { ...state, loading: true, error: false }
        case EMAIL_HISTORY_REQUEST_SUCCESS:
            return {
                ...state,
                history: action.payload.history,
                totalPages: action.payload.totalPages,
                loading: false,
            };
        case EMAIL_HISTORY_REQUEST_FAIL:
            return { ...state, loading: false, error: true }
        case CHANGE_EMAIL_HISTORY_FILTER:
            return { ...state, filter: action.payload }
        default:
            return state;
    }
};

export const selectors = {
    getEmailHistory: (state: RootState) => state.emailHistory.history || [],
    getFilter: (state: RootState) => state.emailHistory.filter,
    isLoading: (state: RootState) => state.emailHistory.loading,
    hasError: (state: RootState) => state.emailHistory.error,
}

function* fetchEmailHistorySaga(action: any): Generator<any, void, any> {
    try {
        const { page, filter, limitPerPage, searchQuery } = action.payload
        const response: any = yield call(fetchEmailHistory, page, limitPerPage, filter, searchQuery)
        const { data, last_page } = response

        yield put(actions.requestEmailHistorySuccess(data, last_page))
    } catch (error) {
        yield put(actions.requestEmailHistoryFail())
    }
}

export function* saga() {
    yield takeLatest(EMAIL_HISTORY_REQUEST, fetchEmailHistorySaga)
}