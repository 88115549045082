import { ActionWithPayload } from "../../../../../setup"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"
import { getLogs } from "./WooCommerceCRUD"
import { IWooCommerce } from "../../models/WooCommerce";

export const LOGS_REQUEST = 'WOOCOMMERCE_LOGS_REQUEST'
export const LOGS_REQUEST_SUCCESS = 'WOOCOMMERCE_LOGS_REQUEST_SUCCESS'
export const LOGS_REQUEST_FAIL = 'WOOCOMMERCE_LOGS_REQUEST_FAIL'

export interface ILogState {
    logs: IWooCommerce[]
    loading: boolean
}

const initialState: ILogState = {
    logs: [],
    loading: false,
}

export const reducer = (
    state: ILogState = initialState,
    action: ActionWithPayload<any>
) => {
    switch (action.type) {
        case LOGS_REQUEST:
            return { ...state, loading: true }
        case LOGS_REQUEST_SUCCESS:
            return { ...state, logs: action.payload.logs || [], loading: false }
        case LOGS_REQUEST_FAIL:
            return { ...state, logs: [], loading: false }
        default:
            return state
    }
}

export const actions = {
    requestLogs: (sortOrder: string) => ({ type: LOGS_REQUEST, payload: sortOrder }),
    requestLogsSuccess: (logs: IWooCommerce[]) => ({
        type: LOGS_REQUEST_SUCCESS,
        payload: { logs },
    }),
    requestLogsFail: () => ({ type: LOGS_REQUEST_FAIL }),
}

function* fetchLogs(action: ActionWithPayload<string>): SagaIterator {
    try {
        const sortOrder = action.payload || 'sort=-created_at'
        const response = yield call(() => getLogs(sortOrder))
        const logs: IWooCommerce[] = response.data || []
        console.log('logs:')
        console.log(logs)
        yield put(actions.requestLogsSuccess(logs))
    } catch (error) {
        yield put(actions.requestLogsFail())
    }
}

export function* saga(): SagaIterator {
    yield takeLatest(LOGS_REQUEST, fetchLogs)
}
